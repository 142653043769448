import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


import Layout from "../components/layout"
import Seo from "../components/seo"
import PostNav from "../components/postNav"
import VideoPlayer from "../components/videoPlayer"

import * as styles from "./project.module.css"

const Project = ({ data }) => {
  const post = data.thePost;
  const imgStyle = {
    boxShadow: '1px 1px 9px rgba(12, 12, 12, 0.125)',
    border: '12px solid rgb(255, 255, 255)',
  };

  return (
    <Layout>
      <Seo title={post.title} />
      <article className="wrapper">

        {post.projectInfo.fbcProjImage && (
            post.projectInfo.fbcProjImage.localFile.publicURL.split('.').pop() === "gif" ?
            <img
              src={post.projectInfo.fbcProjImage.localFile.publicURL}
              alt={post.title}
            /> :
            <GatsbyImage
              image={getImage(post.projectInfo.fbcProjImage.localFile)}
              alt={post.title}
              style={imgStyle}
              backgroundColor={'rgb(255, 255, 255)'}
            />
        )}
        <h1>{post.title}</h1>
        <div className={styles.metaTag}>
          {post.projectInfo.fbcProjData} {" "}
          {post.tags.nodes.map((tag, index) => [
            index > 0 && ", ",
            tag.name,
          ])}
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: post.content }}
        />
        <div className={styles.imageGallery}>
          {post.projectInfo.fbcProjGallery && (
            post.projectInfo.fbcProjGallery.map((imgObj, ind) =>
            <div className={styles.image__wrapper}>
            <GatsbyImage
              image={getImage(imgObj.localFile)}
              alt={post.title}
              style={imgStyle}
              key={ind}
              caption={imgObj.caption}
            />
            <span className={styles.caption} dangerouslySetInnerHTML={{ __html:imgObj.caption }} />
            </div>
            )
          )}
        </div>
        <div>
        {post.projectInfo.fbcExtVideo && (
          <VideoPlayer data={post.projectInfo.fbcExtVideo} title={post.title}/>
        )}
        </div>
        {post.projectInfo.fbcProjUrl === 'http://' || post.projectInfo.fbcProjUrl === 'https://' || post.projectInfo.fbcProjUrl === null ? '' : <p><a href={post.projectInfo.fbcProjUrl}>Visit Website</a></p>}
      </article>
      <PostNav prevPost={data.prevPost} nextPost={data.nextPost} />
    </Layout>
  )
}
export default Project

export const query = graphql`
  query($databaseId: Int!, $nextId: Int, $prevId: Int) {
    thePost: wpProject(databaseId: { eq: $databaseId }, projectInfo: {fbcProjHighlight: {eq: true}}) {
      databaseId
      content
      title
      tags {
        nodes {
          link
          name
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
              )
            }
          }
        }
      }
      projectInfo {
        fbcExtVideo
        fbcProjClient
        fbcProjData
        fbcProjUrl
        fbcProjImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
              )
            }
          }
        }
        fbcProjGallery {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
              )
            }
          }
          caption
        }
      }
    }
    nextPost: wpProject(databaseId: { eq: $nextId }) {
      title
      slug
    }
    prevPost: wpProject(databaseId: { eq: $prevId }) {
      title
      slug
    }
  }
`
