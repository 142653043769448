import React from "react"
import { Link } from "gatsby"
import * as style from "./postNav.module.css"

const PostNav = ({ prevPost, nextPost }) => {
  return (
    <nav className={`${style.postnav} wrapper`}>
      {prevPost ? (
        <Link to={`/projects/${prevPost.slug}`}>
          <div className={style.postnav__head}>Previous project:</div>
          <div className={style.postnav__title}>{prevPost.title}</div>
        </Link>
      ) : <div></div> }

      {nextPost && (
        <Link to={`/projects/${nextPost.slug}`}>
          <div className={style.postnav__head}>Next project:</div>
          <div className={style.postnav__title}>{nextPost.title}</div>
        </Link>
      )}
    </nav>
  )
}

export default PostNav
