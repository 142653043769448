import React from "react"

import * as styles from "./videoPlayer.module.css"


const VideoPlayer = ({data, title}) => {
  const cleanData = data.replace(".js", "");
  const src = "https://cdn.jwplayer.com/players/"+cleanData+".html";
  return (

    <div className={styles.videoContainer}>
      <iframe src={src} width="100%" height="100%" frameBorder="0" title={title} allowFullScreen></iframe>
    </div>
  );
}

export default VideoPlayer
